import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import EventReceiver from '../Chat/EventReceiver';
import Logout from '../Util/Logout';

const initialState = {
  menu: false,
  userMenu: false
}

const Header = ({
  user = null,
  enableEventReceiver = false,
  title=''
}) => {
  const [state, setState] = useState(initialState)

  const show = state.menu ? "active" : "";
  const showMenu = state.menu ? "content-active" : "";
  const usershow = state.userMenu ? "show" : "";


  const toggleUserMenu = () => {
    setState({
      ...state,
      userMenu: !state.userMenu
    });
  }
  const toggleMenu = () => {
    setState({
      ...state,
      menu: !state.menu
    });
  }



  return (
    user ?
      <>
        <meta charSet="utf-8" />
        <meta name="author" content="Softnio" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="@@page-discription" />
        {/* Fav Icon  */}
        <link rel="shortcut icon" href="images/favicon.png" />
        {/* Page Title  */}
        <title>{title}</title>
        {/* StyleSheets  */}
        <link rel="stylesheet" href="assets/css/style.css" />
        <link rel="stylesheet" href="assets/css/custom.css" />
        <link id="skin-default" rel="stylesheet" href="assets/css/theme.css?ver=1.2.0" />
        {enableEventReceiver && <EventReceiver />}
        <div>
          <div className="nk-header nk-header-fixed is-light">
            <div className="container-lg wide-xl">
              <div className="nk-header-wrap">
                <div className="nk-header-brand">
                  <a href="#" className="logo-link">
                    <img className="logo-dark logo-img" src="images/monitor-exam-logo.png" srcSet="images/monitor-exam-logo-2x.png 2x" alt="logo-dark" />
                  </a>
                </div>{/* .nk-header-brand */}
                <div className="nk-header-tools">
                  <ul className="nk-quick-nav">
                    <li onClick={toggleUserMenu} className={"dropdown user-dropdown  " + usershow}>
                      <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded={state.userMenu}>
                        <div className="user-toggle">
                          <div className="user-avatar sm">
                            <img src={'https://d1bumj8k6ax755.cloudfront.net/uploads/' + user.reg_pic.split("'")[1]} class="regpic " alt="reg" style={{ transform: "scale(0.5)", left: "6px" }} />
                            <em className="icon ni ni-user-alt" />
                          </div>
                          <div className="user-name dropdown-indicator d-none d-sm-block">{user.first_name} {user.last_name}</div>
                        </div>
                      </a>
                      <div className={"dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 " + usershow}>
                        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                          <div className="user-card">
                            <div className="user-avatar">
                              <span>{user.first_name.charAt(0)}{user.last_name.charAt(0)}</span>
                            </div>
                            <div className="user-info">
                              <span className="lead-text">{user.first_name} {user.last_name}</span>
                              <span className="sub-text">{user.email}</span>
                            </div>
                            <div className="user-action">
                              <a className="btn btn-icon mr-n2" href="#"><em className="icon ni ni-setting" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="dropdown-inner">
                          <ul className="link-list">
                            <li><a href="\viewprofile"><em className="icon ni ni-user-alt" /><span>View Profile</span></a></li>
                            <li><a href="/security"><em className="icon ni ni-setting-alt" /><span>Security Setting</span></a></li>
                            <li><a href="#"><em className="icon ni ni-activity-alt" /><span>Login Activity</span></a></li>
                          </ul>
                        </div>
                        <div className="dropdown-inner">
                          <ul className="link-list">
                            <Logout />
                          </ul>
                        </div>
                      </div>
                    </li>{/* .dropdown */}
                    <li onClick={toggleMenu} className="d-lg-none">
                      <a href="#" className={"toggle nk-quick-nav-icon mr-n1 " + state.menu ? "active" : ""} data-target="sideNav"><em className="icon ni ni-menu" /></a>
                    </li>
                  </ul>{/* .nk-quick-nav */}
                </div>{/* .nk-header-tools */}
              </div>{/* .nk-header-wrap */}
            </div>{/* .container-fliud */}
          </div>
        </div>
      </>
      :
      <>
        <meta charSet="utf-8" />
        <meta name="author" content="Softnio" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="@@page-discription" />
        {/* Fav Icon  */}
        <link rel="shortcut icon" href="../images/favicon.png" />
        {/* Page Title  */}
        <title>'Login | Monitor Exam'</title>
        {/* StyleSheets  */}
        <link rel="stylesheet" href="../assets/css/style.css?ver=1.2.0" />
        <link rel="stylesheet" href="../assets/css/custom.css" />
        <link id="skin-default" rel="stylesheet" href="../assets/css/theme.css?ver=1.2.0" />

        <header className="header">
          <div className="">
            <Link to="/" className="logo">
              <img src="../../images/monitor-exam-logo.png" alt="logo" width={120} />
            </Link>
            <input className="menu-btn" type="checkbox" id="menu-btn" />
            <label className="menu-icon" htmlFor="menu-btn">
              <span className="navicon" />
            </label>
            <ul className="menu">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/faq">FAQ's</Link></li>
              <li className="hiw"><Link to="/howitworks">How It Works</Link></li>
            </ul>
          </div>
        </header>
      </>
  );
};

export default Header;

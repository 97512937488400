import React, {
  Component,
  useState,
  useEffect
} from 'react'
import {
  createStore
} from 'redux'
import ReactDOM from 'react-dom'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Http from 'axios'
import {
  Redirect,
  withRouter
} from 'react-router-dom'
import NewDashboard from '../Dashboard/NewDashboard'
import NewAdminDashboard from '../Dashboard/NewAdminDashboard'
import NewExamDashboard from '../Dashboard/NewExamDashboard'
import UpcomingExamDashboard from '../Dashboard/UpcomingExamDashboard'
import FlexiExamDashboard from '../Dashboard/FlexiExamDashboard'
import UnscheduledExamDashboard from '../Dashboard/UnscheduledExamDashboard'
import NewOngoingExamDashboard from '../Dashboard/NewOngoingExamDashboard'
import ExamCardDashboard from '../Dashboard/ExamCardDashboard'
import Confirm from '../Auth/Confirm'
import Base64js from 'base64-js'
import { login, logout } from '../Util/apiHelper'
import { clearSessionStorage, getFromSessionStorage, saveToSessionStorage, SESSION_STORAGE_KEYS } from '../Util/sessionStorage'
import Header from '../components/header'
import CustomButton from '../components/button'
import InputField from '../components/textInput'
import { useDispatch } from 'react-redux'
import { SAVE_USER_DETAIL } from '../redux/actionTypes'

/*

1. Show the login window
2. If user exists then send a message user joined to everyone

*/

// Http.defaults.baseURL = 'https://beta.monitorexam.com'
// Http.defaults.headers = {
//   'Content-Type':'application/json',
// }

// Http.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';

class NewLogin extends React.Component {
  constructor() {
    super();
    this.state = {
      phase1: false,
      status: false,
      menu: false,
      security_button: "Use Security Key",
      local_key: false,
      key_exists: false,
      passwordVisible: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    //this.state={name:"She",email:"she@email.com",mobile:"1111122222"};
  }



  openfullscreen(elem) {
    if (elem.requestFullscreen)
      elem.requestFullscreen();
    else if (elem.webkitRequestFullscreen)
      elem.webkitRequestFullscreen();
    else if (elem.msRequestFullscreen)
      elem.msRequestFullscreen();
  }
  checkUserExists = () => {
    Http.get('/user/' + this.state.email + '/exists', {}, null, 'json')
      .then(response => {
        //alert("User Exists");
        console.log("User Exists");
        this.setState({ key_exists: true });
        //            this.getCredentials();
        return true;
      })
      .catch(error => {
        //alert("User does not Exist");
        console.log("User does not Exist");
        this.setState({ key_exists: false });
        return false;
      });
  }

  getCredentials = () => {
    Http.get('/credential/' + this.state.email, { userVer: 'required', txAuthExtension: '' }, null, 'json')
      .then(response => {
        console.log(response)
      })
      .catch(error => {
        //alert("Getting Credentials Failed");
        console.log("Getting Credentials Failed");
      });
  }


  getAssertion = () => {
    this.setState({ security_button: "Wait for prompt" });
    Http.get('/user/' + this.state.email + '/exists', {}, null, 'json').then(response => {
      console.log(response);
    })
      .then(() => {

        //var user_verification = $('#select-verification').find(':selected').val();            
        //            //var txAuthSimple_extension = $('#extension-input').val();
        //
        Http.get('/assertion/' + this.state.email + '?userVer=required&txAuthExtension=', {
          userVer: 'required',
          txAuthExtension: ''
        }, null, 'json')
          .then(makeAssertionOptions => {
            console.log("Assertion Options:");
            console.log(makeAssertionOptions);
            makeAssertionOptions.data.publicKey.challenge = this.bufferDecode(makeAssertionOptions.data.publicKey.challenge);
            makeAssertionOptions.data.publicKey.allowCredentials.forEach(listItem => {
              listItem.id = this.bufferDecode(listItem.id)
            });
            console.log(makeAssertionOptions.data);
            navigator.credentials.get({
              publicKey: makeAssertionOptions.data.publicKey
            })
              .then(credential => {
                console.log(credential);
                this.verifyAssertion(credential);
              }).catch(err => {
                alert("The key does not belong to this device. Please use the device which you used for registration");
                console.log(err.name);
              });
          }).catch(err => {
            alert("FIDO Security is not registered for this user");
            console.log(err.name);
          });
      })
      .catch(error => {
        if (!error.exists) {
          //alert("User not found, try registering one first!");
        }
        return;
      });
  }
  verifyAssertion = (assertedCredential) => {
    //       // Move data into Arrays incase it is super long
    console.log('calling verify')
    let authData = new Uint8Array(assertedCredential.response.authenticatorData);
    let clientDataJSON = new Uint8Array(assertedCredential.response.clientDataJSON);
    let rawId = new Uint8Array(assertedCredential.rawId);
    let sig = new Uint8Array(assertedCredential.response.signature);
    let userHandle = new Uint8Array(assertedCredential.response.userHandle);
    Http.post('/assertion',
      {
        id: assertedCredential.id,
        rawId: this.bufferEncode(rawId),
        type: assertedCredential.type,
        response: {
          authenticatorData: this.bufferEncode(authData),
          clientDataJSON: this.bufferEncode(clientDataJSON),
          signature: this.bufferEncode(sig),
          userHandle: this.bufferEncode(userHandle),
        },
      })
      .then(response => {
        console.log(response);
        this.setState({ status: true });
        // localStorage.setItem('user', JSON.stringify(this.state.user));
        saveToSessionStorage(SESSION_STORAGE_KEYS.user, this.state.user)
        alert("Verification Completed");
      })
      .catch(error => {
        console.log(error);
        this.setState({ status: false });
        alert("Verification Failed");
      });
  }


  bufferEncode(value) {
    // return Buffer.from(value).toString('base64');
    return Base64js.fromByteArray(value)
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "");
  }
  bufferDecode(value) {
    return Uint8Array.from(atob(value), c => c.charCodeAt(0));
  }

  componentWillMount() {
    // //if(window.PublicKeyCredential && window.PublicKeyCredential.isConditionalMediationAvailable) { if (window.PublicKeyCredential.isConditionalMediationAvailable()) alert("Conditional Mediation Available");} 
    // if (window.PublicKeyCredential && window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable) this.state.local_key = true;
    // const user = JSON.parse(localStorage.getItem('user'));
    // //const exam = JSON.parse(localStorage.getItem('exam'));
    // this.state.user = user;
    // //this.state.exam=exam;
    // //console.log(this.state.user);
    // //console.log(this.state.exam);
    // if (user) this.state.status = true;
  }

  componentDidMount() {
    // clearSessionStorage()
    const user = getFromSessionStorage(SESSION_STORAGE_KEYS.user) || null
    if (user) {
      this.setState({
        ...this.state,
        status: true,
        user: user
      })
    }
  }

  //standard function. Assign value to name
  handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({
      [nam]: val
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const errorMsgElement = document.querySelector('span#errorMsg');
    const step1 = document.getElementById("step1");
    const step2 = document.getElementById("step2");
    try {
      const _res = await login(this.state.email || '', this.state.password || '')
      // console.log(_res, 'kasdjlfkasdfk')

      if (_res && Object.keys(_res).length) {
        const user = _res?.user
        saveToSessionStorage(SESSION_STORAGE_KEYS.user, _res?.user)
        // this.setState({
        // user: _res && _res?.user || {},
        // phase1: true
        // });

        //this.getAssertion();
        //                localStorage.setItem('user', JSON.stringify(response));
        //console.log(response);
        //let userStore = createStore(this.state.user);
        errorMsgElement.classList.remove("badge-outline-info");
        errorMsgElement.classList.add("badge-outline-success");
        errorMsgElement.innerHTML = "Username and Password Correct";
        //FIDO Support and there is a local key in the system

        if (user.fido_auth_enabled && this.state && this.state?.local_key) {
          Http.get('/user/' + this.state.email + '/exists', {}, null, 'json').then(response => {
            console.log(response);
          })
            .then(() => {
              /* Switch to step 2 */
              step2.classList.add("active");
              step2.classList.remove("noactive");
              step1.classList.add("noactive");
              document.querySelector('.pro-num.count-step1').classList.remove('active-num');
              document.querySelector('.pro-num.count-step2').classList.add('active-num');
            })
            .catch(error => {
              /* Skip security and move to dashboard*/
              // saveToSessionStorage(SESSION_STORAGE_KEYS.user,this.state.user)
              this.setState({
                ...this.state,
                user: user || {},
                phase1: true,
                status: true
              })
              // localStorage.setItem('user', JSON.stringify(this.state.user));
            })
        }

        else {
          /* Skip security and move to dashboard*/
          // saveToSessionStorage(SESSION_STORAGE_KEYS.user,this.state.user)
          this.setState({
            ...this.state,
            user: _res && _res?.user || {},
            phase1: true,
            status: true
          })
        }             //opens chat for all students and lets them know
        //Chat Support
        const bc = new BroadcastChannel('lims_channel');
        bc.postMessage("joined");
        return <Redirect to="/profile" />
      } else {
        errorMsgElement.innerHTML = "Password did not match";
        // console.log(error);
        errorMsgElement.classList.remove("badge-outline-info");
        errorMsgElement.classList.add("badge-outline-warning");
      }
    } catch (error) {
      console.log(error, 'error is kjjj')
      if (error?.error === 'User is already logged in') {
        const sessionUser = getFromSessionStorage(SESSION_STORAGE_KEYS.user)
        if (sessionUser) {
          alert(`Hello ${sessionUser?.first_name}, You already logged in.`)

          this.setState({
            ...this.state,
            phase1: true,
            status: true,
            user: {
              ...this.state.user,
              ...sessionUser
            }
          })
        } else {
          logout().then(() => {
            console.log('logout')
            this.handleSubmit(event)
          })
        }
      }
    }

  }


  componentDidUpdate(prevProps, prevState) {
    const user = getFromSessionStorage(SESSION_STORAGE_KEYS.user)
    if (user) {
      // const {user={}} = this.state
      console.log(user, 'COMPONENT DID MOUNT')
      if (user?.role === 'invig') {
        this.props.history.push('/flexi')
      } else if (user?.role === 'admin') {
        this.props.history.push('/newDashbaord')
      } else if (user?.role === '') {
        this.props.history.push('/examcarddash')
      }
    }

  }


  //     handleSubmit = (event) => {
  //         event.preventDefault();
  //         const errorMsgElement = document.querySelector('span#errorMsg');
  // 	const step1=document.getElementById("step1");
  // 	const step2=document.getElementById("step2");

  //         const url='user-api/usere/' + this.state.email + '/' + this.state.password
  //         var config = {
  //             method: '',
  //             url: url,
  //             //timeout:100 
  //         };
  //         Http(config)
  //             .then(res => {
  //                 const response = res.data;
  //                 this.setState({
  //                     user: response
  //                 });
  //                 this.setState({
  //                     phase1: true
  //                 });
  // 		//this.getAssertion();
  // //                localStorage.setItem('user', JSON.stringify(response));
  //                 //console.log(response);
  //                 //let userStore = createStore(this.state.user);
  //                 errorMsgElement.classList.remove("badge-outline-info");
  //                 errorMsgElement.classList.add("badge-outline-success"); 
  //                 errorMsgElement.innerHTML = "Username and Password Correct";
  // 		//FIDO Support and there is a local key in the system

  // 		if(this.state.user.fido_auth_enabled && this.state.local_key)
  // 	        {
  //                    Http.get('/user/' + this.state.email + '/exists', {}, null, 'json').then(response=> {
  //                    console.log(response);
  //                    })
  //                    .then(()=> {
  // 			/* Switch to step 2 */
  // 			    step2.classList.add("active");
  // 			    step2.classList.remove("noactive");
  // 		            step1.classList.add("noactive");
  //                             document.querySelector('.pro-num.count-step1').classList.remove('active-num');
  //                             document.querySelector('.pro-num.count-step2').classList.add('active-num');
  // 		   })
  //                    .catch(error=> {
  // 		    /* Skip security and move to dashboard*/
  // 	            this.setState({status:true});
  //                     localStorage.setItem('user', JSON.stringify(this.state.user));
  // 		   });
  //                 }
  // 		else
  // 		{
  // 		    /* Skip security and move to dashboard*/
  // 	            this.setState({status:true});
  //                     localStorage.setItem('user', JSON.stringify(this.state.user));
  // 		}             //opens chat for all students and lets them know
  // 		//Chat Support
  //                 const bc = new BroadcastChannel('lims_channel');
  //                  bc.postMessage("joined");
  //                 //return <Redirect to="/profile" />
  //             })
  //             .catch(function(error) {
  //                 errorMsgElement.innerHTML = "Password did not match";
  //                 console.log(error);
  //                 errorMsgElement.classList.remove("badge-outline-info");
  //                 errorMsgElement.classList.add("badge-outline-warning");
  //             });
  //     }



  /* handleSubmit1 = (event) => {
       event.preventDefault();
       var config = {
           method: 'get',
           url: 'https://api.wit.ai/message?v=20200827&q=I%20am%20tired',
           headers: {
               'Authorization': 'Bearer DDWMOBTNMSYZ7B7JHDLQ233BMLC7WXRG'
           }
       };

       Http(config)
           .then(function(response) {
               console.log(JSON.stringify(response.data));
           })
           .catch(function(error) {
               console.log(error);
           });
       var data = 'This is expected to be sent back as part of response body.';
   }*/


  toggleMenu() {
    this.setState({ menu: !this.state.menu });
  }
  togglePassword() {
    this.setState({ passwordVisible: !this.state.passwordVisible });
  }

  render() {
    const show = this.state.menu ? "active" : "";
    const showMenu = this.state.menu ? "content-active" : "";
    const showIcon = this.state.passwordVisible ? "ni-eye" : "ni-eye-off";
    const showType = this.state.passwordVisible ? "text" : "password";

    // if (!this.state.status) {
    return (
      <>
        <Header />
        {/* app body @s */}
        <div className="nk-app-root">
          <div className="nk-split nk-split-page nk-split-md">
            <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container w-lg-45 bg-lighter">
              <div className="nk-block nk-auth-body mt-5">
                <div className="nk-block-head mt-5">
                  <div className="nk-block-head-content mt-5">
                    <h5 className="nk-block-title" >Login</h5>
                    <div className="nk-block-des">
                      <p>Proctor your online exams with <strong><em>MonitorExam.</em>
                      </strong></p></div>
                  </div>
                </div>{/* .nk-block-head */}
                <div className="nk-block login-process">
                  <div className="row">
                    <div className="col-lg-2">
                      <span className="pro-num count-step1 active-num">1</span>
                    </div>
                    <div className="col-lg-10">
                      <span className="num-heading">Step 1</span>
                      <p>Enter Username and Password</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <span className="pro-num count-step2">2</span>
                    </div>
                    <div className="col-lg-10">
                      <span className="num-heading">Step 2</span>
                      <p>Use Security Key if MFA is enabled</p>
                    </div>
                  </div>
                </div>
              </div>{/* .nk-block */}
              {/* nk-block */}
            </div>{/* nk-split-content */}
            <div className="nk-split-content nk-split-stretch bg-lighter d-flex loginbox" data-content="athPromo" data-toggle-screen="lg" data-toggle-overlay="true">
              <div className="reg-form w-100 w-max-550px loginform">
                <form onSubmit={this.handleSubmit} >
                  <span id="errorMsg" className="badge badge-dim badge-outline-info "></span>
                  <div id="step1" className="step1">
                    <div className="row mb-2">
                      <div className="col-sm-12 form-group">
                        <InputField
                          icon={'mobile'}
                          type={'text'}
                          name={"email"}
                          autocomplete="email webauthn"
                          onChange={this.handleChange}
                          id={"email"}
                          placeholder="Username*"
                          required
                        />
                        {/* <span className="form-icon"><em className="icon ni ni-mobile" /></span>
                          <input type="text" name="email" autocomplete="email webauthn" onChange={this.handleChange} className="form-control form-control-lg" id="email" placeholder="Username*" required /> */}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-sm-12 col-md-12 form-group">
                        <div className="form-control-wrap">
                          <InputField
                            icon={'lock'}
                            type={showType}
                            name={"password"}
                            onChange={this.handleChange}
                            id={"password"}
                            placeholder="Password"
                            required
                            showPassword={this.state.passwordVisible}
                            togglePassword={this.togglePassword}
                            isPasswordField
                          />
                          {/* <span className="form-icon"><em className="icon ni ni-lock" /></span>
                            <a href="#" onClick={this.togglePassword} className="form-icon form-icon-right passcode-switch" data-target="password">
                              <em className={"passcode-icon icon-show icon ni " + showIcon} />
                            </a>
                            <input type={showType} name="password" onChange={this.handleChange} className="form-control form-control-lg " id="password" placeholder="Password" /> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12  col-md-12 form-group">
                        <CustomButton >Login</CustomButton>
                        {/* <button className="btn btn-lg btn-primary btn-block" onClick={this.openfullscreen(document.documentElement)} type="submit" >Login</button> */}
                      </div>
                    </div>
                    <div className="row">
                      <p>To setup new sign in options visit <strong>Security Setting</strong> after signing in</p>
                      <div className="col-sm-12  col-md-12 form-group">
                        <a style={{ "color": "#6576ff", "alignSelf": "left" }} href="/newreset">Forgot Password</a>
                        <a style={{ "color": "#6576ff", "float": "right" }} href="/newiregister">Register</a>
                      </div>
                    </div>
                  </div>
                  <div id="step2" className="row step2 noactive">

                    <div className="col-sm-12  col-md-12 form-group">
                      <em class="icon ni ni-security" style={{ fontSize: "50px", alignItems: "center" }}></em>
                      <h5> Device Authentication</h5>
                      <p>It will work only on the device used to register MonitorExam</p>
                      <p>Click below to <em>Start</em></p>
                    </div>
                    <div className="col-sm-12  col-md-12 form-group">
                      <button className="btn btn-lg btn-primary btn-block" onClick={this.getAssertion} >{this.state.security_button}</button>
                    </div>
                  </div>
                </form>{/* form */}
              </div>
            </div>{/* nk-split-content */}
          </div>{/* nk-split */}
        </div>{/* app body @e */}
        {/* JavaScript */}
      </>
    );

    // }

    // else if ( this.state.user && this.state.user.role == 'invig') {
    //   // this.props.history.push('/flex1')
    //   // return null
    //   return (<FlexiExamDashboard user={
    //     this.state.user
    //   }
    //   />);
    // }


    // else if (this.state.user.role == 'admin') {
    //   return (<NewAdminDashboard />);
    // } else if(this.state.user?.role == '') {
    //   //	    if(this.state.user.live_pic == "") //Pre-Test Verification
    //   //                return ( < Confirm user = { this.state.user } />);
    //   //	    else //Most common for students
    //   //return ( < FlexiExamDashboard user = { this.state.user } />);
    //   return (<ExamCardDashboard user={this.state.user} />);
    // }

  }
}
export default withRouter(NewLogin)
